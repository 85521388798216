import {
  Text,
  SimpleGrid,
  UnstyledButton,
  Group,
  Container,
} from '@mantine/core';
import {
  IconClipboardList,
  IconCheckupList,
  IconPlayerPlay,
  IconHelp,
  IconSchool
} from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import AppStyles from './App.styles';
import {useState} from "react";

const links = [
  {
    title: 'Simular',
    icon: IconPlayerPlay,
    color: 'red',
    link: '/escolher-exame',
    state: { type: 'simulation' },
  },
  {
    title: 'Correcções',
    icon: IconCheckupList,
    color: 'blue',
    link: '/escolher-exame',
    state: { type: 'correction' },
  },
  {
    title: 'Exercícios por tópico',
    icon: IconClipboardList,
    color: 'green',
    link: '/topicos',
    state: { type: 'topics' },
  },
  {
    title: 'Editais',
    icon: IconSchool,
    link: '/editais',
    color: 'orange',
    state: { type: 'support' },
  },
  {
    title: 'Ajuda e suporte',
    icon: IconHelp,
    link: '/support',
    color: 'orange',
    state: { type: 'support' },
  },
];

export default function HomePage() {
  const { classes, theme } = AppStyles();
  const navigate = useNavigate();
  const [opened, setOpened] = useState(true);
  const items = links.map((item) => (
    <UnstyledButton
      onClick={() => {
        if (item.link.includes('support')) {
          window.location.href =
            'https://chat.whatsapp.com/JTxTKj3mLLn6M9ANcBBdVH';
        }
        navigate(item.link, { state: item.state });
      }}
      key={item.title}
      className={classes.item}
      height={50}
    >
      <item.icon color={theme.colors[item.color][6]} size={32} />
      <Text
        sx={{ fontSize: '10pt', marginRight: '17px', marginLeft: '17px' }}
        mt={7}
      >
        {item.title}
      </Text>
    </UnstyledButton>
  ));

  return (
    <>
    <Container mt="md" className={classes.card}>
      <Group position="center">
        <Text className={classes.title}>Bem vindo ao wei exames</Text>
      </Group>
      <SimpleGrid cols={2} mt="md" pb="xl">
        {items}
      </SimpleGrid>
    </Container>
  </>
  );
}
