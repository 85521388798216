import {
  Textarea,
  Container,
  Text,
  TextInput,
  Button,
  Stack,
} from '@mantine/core';

export default function DeleteAccount() {
  return (
    <Container sx={{ backgroundColor: '#25262b' }}>
      <Stack>
        <Text mt="xl" color="white" size="xl" fw={700}>
          Apagar Conta
        </Text>
        <TextInput radius="md" size="md" mt="xl" placeholder="E-mail" />
          <TextInput radius="md" size="md" mt="xl" type="number" placeholder="Número de Telefone" />
        <Textarea radius="md" size="md" mt="xl" placeholder="Motivo" />
        <Button
          sx={(theme) => ({
            height: '44px',
              background: 'rgba(111,137,244,1)',
              color: '#f5f5f5',
          })}
        >
          Submeter
        </Button>
      </Stack>
    </Container>
  );
}
