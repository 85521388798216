import {createStyles} from "@mantine/core";

export default createStyles((theme, _params, getRef) => ({
  label: {
    '&[data-checked]': {
      border: "none",
      '&, &:hover': {
        color: theme.white,
        border: "1px solid rgba(111,137,244,1)"
      },

      [`& .${getRef('iconWrapper')}`]: {
        display: "none"
      },
    },
    '&[data-checked][data-variant="outline"]': {
      border: "2px solid rgba(111,137,244,1)"
    }
  },

  iconWrapper: {
    ref: getRef('iconWrapper'),
    border: "none"
  },
}));
