import {
  Grid,
  Stack,
  Text,
  Container,
  Progress,
  CloseButton,
  Button,
  Image,
  Box,
  Skeleton,
  Modal,
} from '@mantine/core';
import Lamp from './lamp.svg';
import React, { useState, useEffect, Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import { config } from './config';
import Timer from './Timer';
import ReactGA from 'react-ga';

export default function Exam() {
  let location = useLocation();
  const locationParts = location.pathname.split('/');
  const slug = locationParts[2];
  const pathname = locationParts[1];
  ReactGA.pageview(window.location.pathname + window.location.search);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(60);

  if (pathname === 'topico') {
    useEffect(() => {
      try {
        fetch(`https://wei-backend.herokuapp.com/topics/${slug}`)
          .then((response) => {
            if (response.status === 404) {
              navigate('/404');
              return;
            }
            return response.json();
          })
          .then((data) => {
            setIsLoading(false);
            setExam(data);
            setQuestions(data.questions);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (error) {
        console.log(error);
      }
    }, []);
  }

  if (pathname === 'exame' || pathname === 'correcao') {
    useEffect(() => {
      try {
        fetch(`https://wei-backend.herokuapp.com/exams/${slug}`)
          .then((response) => {
            if (response.status === 404) {
              navigate('/404');
              return;
            }
            return response.json();
          })
          .then((data) => {
            data.questions = data.questions.map(
              (question) => question['questionId']
            );
            setMinutes(data.time);
            setIsLoading(false);
            setExam(data);
            setQuestions(data.questions.sort((a, b) => a.number - b.number));
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (error) {
        navigate('/404');
      }
    }, []);
  }

  if (pathname === 'resolucao') {
    useEffect(() => {
      const { state } = location;
      const { questionsWithCorrection, label } = state;
      if (questionsWithCorrection && questionsWithCorrection.length > 0) {
        setQuestions(questionsWithCorrection);
        setExam({
          total_questions: questionsWithCorrection.length - 1,
          label: `Resolução: ${label}`,
        });
        setIsLoading(false);
      } else {
        navigate('/404');
        return;
      }
    }, []);
  }

  const [isLoading, setIsLoading] = useState(true);
  const [opened, setOpened] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('');
  const [questions, setQuestions] = useState([]);
  let [exam, setExam] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showCorrection, setShowCorrection] = useState(false);
  const [showTip, setShowTip] = useState(false);
  const [isStopped, setIsStopped] = useState(false);

  const displayCorrection = () => {
    setShowCorrection(!showCorrection);
  };

  const ShowCorrection = showCorrection ? (
    <IconChevronUp onClick={displayCorrection} />
  ) : (
    <IconChevronDown onClick={displayCorrection} />
  );

  const openVideoLink = () => {
    window.open(questions[currentQuestionIndex].correction.videoLink, '_blank');
  };

  const ShowTip = showTip ? (
    <IconChevronUp
      style={{ marginRight: '5px' }}
      color="#E3A102"
      onClick={() => setShowTip(!showTip)}
    />
  ) : (
    <IconChevronDown
      style={{ marginRight: '5px' }}
      color="#E3A102"
      onClick={() => setShowTip(!showTip)}
    />
  );
  const selectOption = (letter) => {
    if (pathname !== 'exame') {
      return;
    }
    setSelectedOption(letter);
    if (selectedOptions.length < 1) {
      setSelectedOptions([
        {
          questionNumber: questions[currentQuestionIndex].number,
          option: letter,
          questionId: questions[currentQuestionIndex]._id,
        },
      ]);
    } else {
      setSelectedOptions([
        ...selectedOptions,
        {
          questionNumber: questions[currentQuestionIndex].number,
          option: letter,
          questionId: questions[currentQuestionIndex]._id,
        },
      ]);
    }
    setTimeout(() => {
      setSelectedOption('');
      if (currentQuestionIndex >= questions.length - 1) {
        setIsStopped(true);
      } else {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
    }, 350);
  };

  const onSubmitExam = (minutes, seconds) => {
    setIsStopped(false);
    navigate('/resultado', {
      state: {
        time: {
          minutes: minutes,
          seconds: seconds,
        },
        examId: exam._id,
        answers: selectedOptions,
        pathname: pathname,
      },
    });
  };
  return (
    <MathJaxContext config={config} key={currentQuestionIndex}>
      <Container>
        <Stack>
          <Stack>
            <Modal
              onClose={() => {
                setOpened(false);
              }}
              closeOnEscape={true}
              closeOnClickOutside={true}
              opened={opened}
              withCloseButton={false}
              title="Deseja cancelar?"
              centered
            >
              <Text size="xl">
                Mas jshdjkasdk chefe, como vais admitir assim? exame de admissão
                não tem way, estuda!
              </Text>
              <Container align="right" mt="xl">
                <Button
                  onClick={() => {
                    setOpened(false);
                  }}
                  variant="subtle"
                  sx={(theme) => ({
                    background: 'rgba(111,137,244,1)',
                    color: '#f5f5f5',
                  })}
                >
                  Continuar
                </Button>
                <Button
                  sx={(theme) => ({
                    color: '#A4B7FF',
                    background: 'none',
                  })}
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  Cancelar exame
                </Button>
              </Container>
            </Modal>
            <Grid align="center">
              <Grid.Col span={10}>
                <Text
                  color={'white'}
                  className={isLoading ? `hidden` : `shown`}
                >
                  {pathname === 'correcao' ? 'Correção - ' : null} {exam.label}
                </Text>
                <Skeleton
                  className={!isLoading ? `hidden` : `shown`}
                  height={15}
                  radius={0}
                />
              </Grid.Col>
              <Grid.Col align="right" span={2}>
                <CloseButton
                  onClick={() => {
                    if (pathname === 'topico') {
                      window.history.back();
                    }
                    if (pathname !== 'correcao') {
                      setOpened(true);
                    } else {
                      navigate('/');
                    }
                  }}
                  title="Fechar modal"
                  size="xl"
                  iconSize={20}
                />
              </Grid.Col>
            </Grid>
            <Grid align="center">
              <Grid.Col span={2}>
                <Skeleton
                  className={!isLoading ? `hidden` : `shown`}
                  height={15}
                  radius={0}
                />
                <Text
                  className={isLoading ? `hidden` : `shown`}
                  weight="bold"
                  color="blue"
                  size={pathname === 'topico' ? 'xl' : undefined}
                >
                  {isLoading ? null : pathname === 'topico' ||
                    pathname === 'correcao' ||
                    pathname === 'resolucao' ? (
                    <MathJax>{'$\\infty $'}</MathJax>
                  ) : (
                    <Timer
                      initialMinutes={exam.time}
                      onFinish={onSubmitExam}
                      isStopped={isStopped}
                      state={{
                        minutes,
                        seconds,
                        setSeconds,
                        setMinutes,
                      }}
                    />
                  )}
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Skeleton
                  className={!isLoading ? `hidden` : `shown`}
                  height={15}
                  radius={0}
                />
                <Progress
                  className={isLoading ? `hidden` : ``}
                  color="white"
                  value={
                    ((pathname === 'correcao' ||
                    pathname === 'resolucao' ||
                    pathname === 'topico'
                      ? currentQuestionIndex + 1
                      : selectedOptions.length) /
                      exam.total_questions) *
                    100
                  }
                />
              </Grid.Col>
              <Grid.Col span={2}>
                <Skeleton
                  className={!isLoading ? `hidden` : `shown`}
                  height={15}
                  radius={0}
                />
                <Text
                  color={'white'}
                  className={isLoading ? `hidden` : `shown`}
                  weight="bold"
                >
                  {currentQuestionIndex + 1}/{exam.total_questions}
                </Text>
              </Grid.Col>
            </Grid>
            <Skeleton
              className={!isLoading ? `hidden` : `shown`}
              height={15}
              radius={0}
            />
            {questions[currentQuestionIndex] && (
              <Fragment>
                <Text
                  size="xl"
                  mb="md"
                  mt="md"
                  color={'white'}
                  className={isLoading ? `hidden` : `shown`}
                >
                  <MathJax>
                    {pathname !== 'topico'
                      ? questions[currentQuestionIndex].number
                      : currentQuestionIndex + 1}
                    {`. ${questions[currentQuestionIndex].description}`}
                  </MathJax>
                </Text>
                {!isLoading && questions[currentQuestionIndex].figureUrl && (
                  <Image
                    sx={{ margin: '0 auto' }}
                    className={isLoading ? `hidden` : `shown`}
                    alt=""
                    hidden={!questions[currentQuestionIndex].figureUrl}
                    radius="md"
                    width={340}
                    src={questions[currentQuestionIndex].figureUrl}
                  />
                )}
              </Fragment>
            )}
            <Skeleton
              className={!isLoading ? `hidden` : `shown`}
              height={200}
              radius={10}
            />
            <Stack>
              <Skeleton
                className={!isLoading ? `hidden` : `shown`}
                height={60}
                radius={0}
              />
              {!isLoading && questions[currentQuestionIndex].options && (
                <>
                  {questions[currentQuestionIndex].options.map(
                    ({ letter, text, figureUrl }) => (
                      <Fragment key={letter}>
                        <Box
                          className={isLoading ? `hidden` : `shown`}
                          onClick={(event) => {
                            if (
                              (pathname === 'correcao' ||
                                pathname === 'resolucao') &&
                              questions[currentQuestionIndex].correction &&
                              questions[currentQuestionIndex].correction
                                .option === letter
                            ) {
                              setShowCorrection(!showCorrection);
                            } else {
                              selectOption(letter);
                            }
                          }}
                          sx={(theme) => ({
                            borderRadius: theme.radius.md,
                            backgroundColor:
                              (pathname === 'correcao' ||
                                pathname === 'resolucao') &&
                              letter ===
                                questions[currentQuestionIndex].correction
                                  .option
                                ? '#0b2b1b'
                                : selectedOption === letter
                                ? '#242937'
                                : questions[currentQuestionIndex]
                                    .selectedOption === letter &&
                                  letter !==
                                    questions[currentQuestionIndex].correction
                                ? '#200101'
                                : '#121212',
                            border:
                              (pathname === 'correcao' ||
                                pathname === 'resolucao') &&
                              letter ===
                                questions[currentQuestionIndex].correction
                                  .option
                                ? '2px solid #037f71'
                                : selectedOption === letter
                                ? '2px solid #8094FF'
                                : questions[currentQuestionIndex]
                                    .selectedOption === letter &&
                                  letter !==
                                    questions[currentQuestionIndex].correction
                                ? '2px solid #E73A4E'
                                : '2px solid #3a3a3a',
                            padding: '10px',
                            color: 'white',
                            '&:hover': {
                              cursor: 'pointer',
                            },
                          })}
                        >
                          <Grid align="center">
                            <Grid.Col span={2}>
                              <Text
                                align="center"
                                sx={(theme) => ({
                                  borderRadius: theme.radius.md,
                                  backgroundColor:
                                    (pathname === 'correcao' ||
                                      pathname === 'resolucao') &&
                                    letter ===
                                      questions[currentQuestionIndex].correction
                                        .option
                                      ? '#25ac9c'
                                      : selectedOption === letter
                                      ? '#5476F0'
                                      : questions[currentQuestionIndex]
                                          .selectedOption === letter &&
                                        letter !==
                                          questions[currentQuestionIndex]
                                            .correction
                                      ? '#E73A4E'
                                      : '#222222',
                                  color: 'white',
                                  padding: '10px',
                                  width: '20px',
                                })}
                              >
                                {letter}
                              </Text>
                            </Grid.Col>
                            <Grid.Col span={8}>
                              {text ? (
                                <Text size="xl">
                                  <MathJax>{text}</MathJax>
                                </Text>
                              ) : (
                                <Image
                                  className={isLoading ? `hidden` : `shown`}
                                  alt=""
                                  hidden={!figureUrl}
                                  radius="md"
                                  width="340"
                                  height="200"
                                  src={figureUrl}
                                />
                              )}
                            </Grid.Col>
                            <Grid.Col span={2}>
                              {pathname === 'correcao' &&
                                letter ===
                                  questions[currentQuestionIndex].correction
                                    .option &&
                                questions[currentQuestionIndex].correction.steps
                                  .length > 0 &&
                                ShowCorrection}
                            </Grid.Col>
                          </Grid>
                        </Box>
                        {pathname === 'correcao' &&
                          letter ===
                            questions[currentQuestionIndex].correction
                              .option && (
                            <Stack
                              className={!showCorrection ? `hidden` : `shown`}
                              sx={(theme) => ({
                                color: 'white',
                                backgroundColor: '#121212',
                                padding: '20px',
                                borderTopRightRadius: theme.radius.md,
                                borderTopLeftRadius: theme.radius.md,
                              })}
                            >
                              {questions[
                                currentQuestionIndex
                              ].correction.steps.map((text, index) => (
                                <Fragment key={`${index}-${text}`}>
                                  <span
                                    style={{
                                      backgroundColor: '#0b2b1b',
                                      fontSize: '14px',
                                      paddingLeft: '10px',
                                      paddingTop: '2px',
                                      paddingBottom: '2px',
                                      paddingRight: '10px',
                                      display: 'inline-block',
                                      marginBottom: '10px',
                                      marginTop: '10px',
                                      border: '1px solid #037f71',
                                      borderRadius: '20px',
                                    }}
                                  >
                                    Passo {index + 1}
                                  </span>
                                  <MathJax
                                    style={{
                                      paddingBottom: '10px',
                                      paddingTop: '10px',
                                    }}
                                    key={currentQuestionIndex + index}
                                  >
                                    {text}
                                  </MathJax>
                                </Fragment>
                              ))}
                              <span
                                style={{
                                  backgroundColor: '#0b2b1b',
                                  fontSize: '14px',
                                  paddingLeft: '10px',
                                  paddingTop: '2px',
                                  paddingBottom: '2px',
                                  paddingRight: '10px',
                                  display: 'inline-block',
                                  marginBottom: '10px',
                                  marginTop: '10px',
                                  border: '1px solid #037f71',
                                  borderRadius: '20px',
                                }}
                              >
                                ✨ Solução
                              </span>
                              <p>
                                A opção correcta é a{' '}
                                {
                                  questions[currentQuestionIndex].correction
                                    .option
                                }
                              </p>
                              {questions[currentQuestionIndex].correction
                                .videoLink && (
                                <Text
                                  onClick={openVideoLink}
                                  mt={50}
                                  align="right"
                                  size="lg"
                                  sx={{ color: '#A4B7FF', fontWeight: '500' }}
                                >
                                  Assistir video →
                                </Text>
                              )}
                            </Stack>
                          )}
                      </Fragment>
                    )
                  )}
                </>
              )}
              <Skeleton
                className={!isLoading ? `hidden` : `shown`}
                height={60}
                radius={0}
              />
              {questions[currentQuestionIndex] &&
                questions[currentQuestionIndex].tip &&
                pathname !== 'correcao' &&
                pathname !== 'resolucao' && (
                  <Box
                    className={isLoading ? `hidden` : `shown`}
                    sx={(theme) => ({
                      borderRadius: theme.radius.md,
                      backgroundColor: '#1f1d19',
                      border: '2px solid #563f06',
                      padding: '10px',
                    })}
                  >
                    <Grid align="center">
                      <Grid.Col span={2}>
                        <Text
                          align="center"
                          sx={(theme) => ({
                            borderRadius: theme.radius.md,
                            backgroundColor: '#2f2600',
                            width: '24px',
                            padding: '10px',
                            color: '#e3a102',
                          })}
                        >
                          <Image className="tip-image" src={Lamp} />
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={2}>
                        <Text color="#E3A102" size="xl">
                          Dica
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={8} align="right">
                        {ShowTip}
                      </Grid.Col>
                      {showTip && (
                        <Text
                          color="white"
                          sx={() => ({ color: '#E3A102' })}
                          size="lg"
                          p={10}
                        >
                          <MathJax>
                            {questions[currentQuestionIndex].tip}
                          </MathJax>
                        </Text>
                      )}
                    </Grid>
                  </Box>
                )}
              <Skeleton
                className={!isLoading ? `hidden` : `shown`}
                height={70}
                radius={0}
              />
              {!isLoading && pathname === 'topico' ? (
                <Text italic={true} sx={{ color: '#acacace0' }}>
                  Fonte: Exame de {questions[currentQuestionIndex]['examLabel']}
                </Text>
              ) : null}
              <Text
                className={
                  isLoading ||
                  ((pathname === 'correcao' ||
                    pathname === 'resolucao' ||
                    pathname === 'topico') &&
                    currentQuestionIndex <= 0)
                    ? `hidden`
                    : `shown`
                }
                onClick={() => {
                  if (
                    (pathname === 'correcao' ||
                      pathname === 'resolucao' ||
                      pathname === 'topico') &&
                    currentQuestionIndex > 0
                  ) {
                    setCurrentQuestionIndex(currentQuestionIndex - 1);
                  } else {
                    if (currentQuestionIndex <= questions.length - 1) {
                      setCurrentQuestionIndex(currentQuestionIndex + 1);
                    }
                  }
                }}
                align="center"
                weight="bold"
                size="md"
                sx={(theme) => ({
                  color: '#A4B7FF',
                  cursor: 'pointer',
                })}
              >
                {pathname === 'correcao' ||
                pathname === 'resolucao' ||
                pathname === 'topico'
                  ? 'Anterior'
                  : 'Saltar'}
              </Text>
              <Skeleton
                className={!isLoading ? `hidden` : `shown`}
                height={30}
                radius={0}
              />
            </Stack>
          </Stack>
          <Skeleton
            className={!isLoading ? `hidden` : `shown`}
            height={45}
            radius={0}
          />
          <Button
            className={isLoading ? `hidden` : `shown`}
            disabled={
              (pathname === 'correcao' ||
                pathname === 'resolucao' ||
                pathname === 'topico') &&
              currentQuestionIndex >= questions.length - 1
            }
            sx={(theme) => ({
              height: '44px',
              background:
                'linear-gradient(90deg, rgba(111,137,244,1) 62%, rgba(245,154,244,1) 90%, rgba(252,189,205,1) 98%)',
            })}
            onClick={() => {
              if (
                pathname === 'correcao' ||
                pathname === 'resolucao' ||
                pathname === 'topico'
              ) {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
                selectedOptions.push({
                  questionNumber: questions[currentQuestionIndex].number,
                  option: questions[currentQuestionIndex].correction.option,
                  questionId: questions[currentQuestionIndex]._id,
                });
              } else {
                setIsStopped(true);
              }
              setShowCorrection(false);
            }}
          >
            {pathname === 'correcao' ||
            pathname === 'resolucao' ||
            pathname === 'topico'
              ? 'Próxima'
              : 'Submeter'}
          </Button>
        </Stack>
      </Container>
    </MathJaxContext>
  );
}
