import { Container } from '@mantine/core';

export default function () {
  return (
    <Container sx={{ color: 'white' }}>
      <h1>Termos e Condições - Wei Exames</h1>
      <p>
        Bem-vindo aos Termos e Condições dos Exames Wei. Ao utilizar nossos
        serviços, você concorda em cumprir com os seguintes termos e condições:
      </p>

      <h2>1. Elegibilidade</h2>
      <p>
        Você deve atender aos requisitos de elegibilidade especificados para
        participar dos exames Wei. Isso pode incluir a conclusão de cursos
        preparatórios, a idade mínima e outros critérios estabelecidos.
      </p>

      <h2>2. Registo</h2>
      <p>
        Você concorda em fornecer informações precisas e actualizadas ao se
        registar para um exame Wei. Qualquer informação falsa ou enganosa
        resultará em desqualificação.
      </p>

      <h2>3. Propriedade Intelectual</h2>
      <p>
        Você reconhece que todos os materiais de exame e conteúdo são protegidos
        por direitos autorais e outras leis de propriedade intelectual. Você não
        pode reproduzir, distribuir ou usar esses materiais de forma não
        autorizada.
      </p>

      <h2>4. Conduta</h2>
      <p>
        Você concorda em manter um comportamento ético durante o exame e seguir
        as directrizes fornecidas pelos supervisores. Qualquer tentativa de
        fraude ou desonestidade resultará em acções disciplinares.
      </p>

      <h2>5. Resultados</h2>
      <p>
        Os resultados dos exames Wei são finais e incontestáveis, a menos que
        haja uma discrepância evidente. Você pode solicitar a revisão de seus
        resultados conforme os procedimentos especificados.
      </p>

      <h2>6. Privacidade</h2>
      <p>
        Nós respeitamos sua privacidade. Suas informações pessoais serão
        tratadas de acordo com nossa Política de Privacidade.
      </p>

      <h2>7. Cancelamentos e Reembolsos</h2>
      <p>
        Os cancelamentos e reembolsos estão sujeitos a políticas específicas,
        que serão comunicadas durante o processo de registo.
      </p>

      <h2>8. Alterações nos Termos</h2>
      <p>
        Reservamo-nos o direito de fazer alterações nestes termos e condições a
        qualquer momento. As alterações serão comunicadas a você de forma
        apropriada.
      </p>

      <h2>9. Rescisão</h2>
      <p>
        Podemos rescindir ou suspender seu acesso aos nossos serviços a qualquer
        momento, caso você viole estes termos.
      </p>

      <h2>10. Lei Aplicável</h2>
      <p>
        Estes termos são regidos pelas leis do [inserir jurisdição] e quaisquer
        disputas serão resolvidas por meio dos tribunais competentes nessa
        jurisdição.
      </p>

      <p>
        Ao utilizar os serviços Wei Exams, você concorda com esses termos e
        condições. Se você não concorda com eles, não utilize nossos serviços.
        Se você tiver alguma dúvida ou preocupação, entre em contacto connosco.
      </p>

      <p>
        Estes termos são fornecidos apenas como um modelo inicial. Consulte um
        advogado para personalizar esses termos consoante as necessidades
        específicas da Wei Exams e para garantir que eles estejam consoante as
        leis locais e regulamentos aplicáveis.
      </p>
    </Container>
  );
}
