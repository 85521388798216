import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Stack, Text, Box } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons';
export default function Courses() {
  let location = useLocation();
  const navigate = useNavigate();
  const locationParts = location.pathname.split('/');
  const slug = locationParts[2];
  const pathname = locationParts[1];
  const course = slug.substring(0, 1).toUpperCase() + slug.substring(1)
  return (
    <Container sx={{paddingLeft: 8, paddingRight: 8}}>
      <Stack>
        <Text mt="xl" color={'white'} size="xl" fw={700}>
          <IconArrowLeft
            style={{ verticalAlign: 'middle', marginRight: 10 }}
            onClick={() => {
              window.history.back();
            }}
          />{' '}
          {course}
        </Text>
        <Box
          sx={{
            backgroundColor: '#37383C',
            padding: 20,
            borderRadius: 8,
            marginTop: 30,
          }}
        >
          <Text color={'white'} size="md" fw={900}>
            {course}
          </Text>
          <div style={{ marginTop: 15 }}>
            <span
              style={{
                backgroundColor: 'rgba(235, 255, 243, 0.10)',
                fontSize: 12,
                padding: '4px 8px',
                display: 'inline-block',
                marginBottom: 10,
                marginTop: 10,
                borderRadius: '20px',
                color: '#CFFFE2',
              }}
            >
              Matemática II: 50%
            </span>
            <span
              style={{
                backgroundColor: 'rgba(235, 255, 243, 0.10)',
                fontSize: 12,
                padding: '4px 8px',
                display: 'inline-block',
                marginBottom: 10,
                marginTop: 10,
                borderRadius: '20px',
                color: '#CFFFE2',
                marginLeft: 5,
              }}
            >
              Português II: 50%
            </span>
            <span
              style={{
                backgroundColor: 'rgba(235, 255, 243, 0.10)',
                fontSize: 12,
                padding: '4px 8px',
                display: 'inline-block',
                marginBottom: 10,
                marginTop: 10,
                borderRadius: '20px',
                color: '#CFFFE2',
                marginLeft: 5,
              }}
            >
              50 vagas
            </span>
          </div>
          <Text mt="lg" color={'white'} size="lg" fw={700}>
            12ª classe do SNE com Matemática e Português no certificado (ou
            curso de ensino técnico médio do ramo equivalente).
          </Text>
        </Box>
      </Stack>
    </Container>
  );
}
