import './styles.css';
import {Box, Center} from '@mantine/core';
import { Chip, Button, Stack, Text, Divider } from '@mantine/core';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SelectExamStyles from './SelectExam.styles';
import ReactGA from "react-ga";

export default function App() {
  const { classes } = SelectExamStyles();
  const location = useLocation();
  const { state } = location;
  const [hasSelectedStepOne, setHasSelectedStepOne] = useState(false);
  const [hasSelectedStepTwo, setHasSelectedStepTwo] = useState(false);
  const [hasSelectedStepThree, setHasSelectedStepThree] = useState(false);
  const [hasSelectedStepFour, setHasSelectedStepFour] = useState(false);
  const [specifiedExam, setSpecifiedExam] = useState({});
  const navigate = useNavigate();
  ReactGA.pageview(window.location.pathname + window.location.search);

  const getUniversity = (value) => {
    setSpecifiedExam({ ...specifiedExam, university: value });
  };
  const getSubject = (value) => {
    setSpecifiedExam({ ...specifiedExam, subject: value });
  };
  const getYear = (value) => {
    setSpecifiedExam({ ...specifiedExam, year: value });
  };

  const getVariation = (value) => {
    setSpecifiedExam({
      ...specifiedExam,
      variation: value,
    });
  };

  const openSpecifiedExam = () => {
    const { university, variation, subject, year } = specifiedExam;
    navigate(
      `/${
        (state.type && state.type === 'correction') ? 'correcao' : 'exame'
      }/admissao-${university.toLowerCase()}-${subject}${variation && university !== 'up' ? `-${variation}`: ""}-${year}`
    );
  };

  const Universities = (
    <Chip.Group
      onChange={getUniversity}
      position="center"
      mt={15}
      onClick={() => {
        setHasSelectedStepOne(true);
      }}
    >
      <Chip value="uem" classNames={classes} className="chip-height" size="lg">
        UEM
      </Chip>
      <Chip value="up" classNames={classes} className="chip-height" size="lg">
        UP
      </Chip>
    </Chip.Group>
  );

  const Subjects = (
    <Chip.Group
      className={hasSelectedStepOne ? 'display' : 'conceal'}
      position="center"
      mt={15}
      onChange={getSubject}
      onClick={() => {
        setHasSelectedStepTwo(true);
      }}
    >
      <Chip
        classNames={classes}
        className="chip-height"
        value="matematica"
        size="lg"
      >
        Matemática
      </Chip>
      <Chip
        classNames={classes}
        className="chip-height"
        value="fisica"
        size="lg"
      >
        Física
      </Chip>
      <Chip
        classNames={classes}
        className="chip-height"
        value="quimica"
        size="lg"
      >
        Química
      </Chip>
    </Chip.Group>
  );

  const mathSubjectVariations = (
    <Chip.Group
      onChange={getVariation}
      className={
        specifiedExam.university === 'uem' &&
        specifiedExam.subject &&
        specifiedExam.subject.includes('matematica') &&
        parseInt(specifiedExam.year) >= 2020
          ? 'display'
          : displayedVariation === "CHEMISTRY" || displayedVariation === "PHYSICS" ? 'hide' : 'hide'
      }
      onClick={() => {
        setHasSelectedStepFour(true);
      }}
      mt={15}
      position="center"
    >
      <Chip classNames={classes} className="chip-height" value="3" size="lg">
        Matemática III
      </Chip>
    </Chip.Group>
  );

  const chemistrySubjectVariations = (
    <Chip.Group
      onChange={getVariation}
      className={
        specifiedExam.university === 'uem' &&
        specifiedExam.subject &&
        specifiedExam.subject.includes('quimica') &&
        parseInt(specifiedExam.year) >= 2021
          ? 'display'
          : displayedVariation === "PHYSICS" || displayedVariation === "MATHS" ? 'hide' : 'hide'
      }
      onClick={() => {
        setHasSelectedStepFour(true);
      }}
      mt={15}
      position="center"
    >
      <Chip classNames={classes} className="chip-height" value="1" size="lg">
        Química I
      </Chip>
      <Chip classNames={classes} className="chip-height" value="2" size="lg">
        Química II
      </Chip>
    </Chip.Group>
  );

  const physicsSubjectVariations = (
    <Chip.Group
      onChange={getVariation}
      className={
        specifiedExam.university === 'uem' &&
        specifiedExam.subject &&
        specifiedExam.subject.includes('fisica') &&
        parseInt(specifiedExam.year) >= 2021
          ? 'display'
          : displayedVariation === "CHEMISTRY" || displayedVariation === "MATHS" ? 'hide' : 'hide'
      }
      onClick={() => {
        setHasSelectedStepFour(true);
      }}
      mt={15}
      position="center"
    >
      <Chip classNames={classes} className="chip-height" value="1" size="lg">
        Física I
      </Chip>
      <Chip classNames={classes} className="chip-height" value="2" size="lg">
        Física II
      </Chip>
    </Chip.Group>
  );

  const Years = (
    <Chip.Group
      className={hasSelectedStepTwo ? 'display' : 'conceal'}
      position="center"
      mt={15}
      onChange={getYear}
      onClick={() => {
        setHasSelectedStepThree(true);
      }}
    >
      <Chip classNames={classes} className="chip-height" value="2022" size="lg">
        2022
      </Chip>
    </Chip.Group>
  );

  const displayedVariation = (specifiedExam.university === 'uem' &&
    specifiedExam.subject &&
    specifiedExam.subject.includes('quimica') &&
    parseInt(specifiedExam.year) >= 2021) ? "CHEMISTRY" : (specifiedExam.university === 'uem' &&
    specifiedExam.subject &&
    specifiedExam.subject.includes('fisica')) ? "PHYSICS": "MATH";

  return (
    <Center>
      <Stack style={{ marginTop: '20px', width: '350px' }}>
        <Text weight="bold" align="center" color={'white'} size="xl">
          Escolha o exame para {state.type && state.type === 'correction'? "ver a correção": "simular"}
        </Text>
        <Divider my="sm" variant="dashed" />
        {Universities}
        {Subjects}
        {Years}
        {mathSubjectVariations}
        {chemistrySubjectVariations}
        {physicsSubjectVariations}
        <Box mt={25}/>
        <Button
          sx={(theme) => ({
            height: '44px',
            background:
              'linear-gradient(90deg, rgba(111,137,244,1) 62%, rgba(245,154,244,1) 90%, rgba(252,189,205,1) 98%)',
          })}
          onClick={openSpecifiedExam}
          disabled={
            specifiedExam.university === 'uem' &&
            specifiedExam.subject &&
            specifiedExam.subject.includes('matematica')
              ? !hasSelectedStepFour
              : !hasSelectedStepThree
          }
        >
          Iniciar
        </Button>
      </Stack>
    </Center>
  );
}
