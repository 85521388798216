const config = {
  tex: {
    inlineMath: [
      ["$", "$"],
      ["\\(", "\\)"],
    ],
    displayMath: [
      ["$$", "$$"],
      ["\\[", "\\]"],
    ],
    packages: {'[+]': ['mhchem']}
  },
  loader: {
    load: ["input/tex", "output/svg"],
  },
  startup: {
    typeset: false
  }
};

export {config}
