import { useEffect } from 'react';

export default function Timer({ initialMinutes = 90, onFinish, isStopped, state }) {

  if (isStopped) {
    onFinish(state.minutes, state.seconds)
  }
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (state.seconds > 0) {
        state.setSeconds(state.seconds - 1);
      }
      if (state.seconds === 0) {
        if (state.minutes === 0) {
          clearInterval(myInterval);
          onFinish(state.minutes, state.seconds)
        } else {
          state.setMinutes(state.minutes - 1);
          state.setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <>
      {state.minutes === 0 && state.seconds === 0 ? null : (
        <span style={{
          color: "rgba(111,137,244,1)"
        }}>
          {state.minutes}:{state.seconds < 10 ? `0${state.seconds}` : state.seconds}
        </span>
      )}
    </>
  );
}
