import {
  Text,
  Grid,
  CloseButton,
  Container,
  Button,
  Stack,
} from '@mantine/core';
import PriceCard from './PriceCard';
import './styles.css';
import { Link } from 'react-router-dom';

export default function Pricing() {
  const pricings = [
    {
      title: 'Básico',
      price: 50.0,
      features: [
        'Resolução comentada',
        'Simulação de exames de admissão',
        'Dicas na resolução',
        'Simulação de exames do nível médio',
      ],
      isSelected: false,
    },
    {
      title: 'Admitido',
      price: 100.0,
      features: [
        'Resolução comentada',
        'Simulação de exames de admissão',
        'Dicas na resolução',
        'Dicas e testes para melhorar o desempenho no exame',
        'Acesso a correcções e exames Offline',
      ],
      isSelected: true,
    },
    {
      title: 'Maza',
      price: 250.0,
      features: [
        'Resolução comentada',
        'Simulação de exames de admissão',
        'Dicas na resolução',
        'Simulação de exames do nível médio',
        'Dicas e testes para melhorar o desempenho no exame',
        'Sessões de mentoria ou explicação',
        'Definir metas e reportes',
      ],
      isSelected: false,
    },
  ];
  return (
    <>
      <Stack style={{ fontFamily: 'Inter, sans-serif' }}>
        <Grid align="center" style={{ width: '100%' }}>
          <Grid.Col span={10}>
            <Text size="xl" weight="600">
              Escolha o plano
            </Text>
          </Grid.Col>
          <Grid.Col span={2}>
            <CloseButton
              title="Close popover"
              size="xl"
              component={Link}
              to="/"
              iconSize={20}
            />
          </Grid.Col>
        </Grid>
        <Container
          style={{ marginLeft: '10px', paddingLeft: 0, width: '100%' }}
        >
          {pricings.map((price) => (
            <PriceCard price={price} />
          ))}
        </Container>
        <Button
          sx={(theme) => ({
            borderRadius: theme.radius.md,
            padding: '10px',
            height: '40px',
          })}
        >
          Adquirir plano admitido
        </Button>
      </Stack>
    </>
  );
}
