import {createStyles} from "@mantine/core";

export default createStyles((theme) => ({
  box: {
    backgroundColor: isSelected
      ? 'rgba(84, 118, 240, 0.09) '
      : 'rgba(226, 226, 226, 0.25)',
    border: isSelected ? '1px solid #5F82FF' : 'none',
    textAlign: 'left',
    width: '100%',
    padding: theme.spacing.md,
    paddingBottom: theme.spacing.xl,
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.sm,
    fontFamily: 'Inter',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(84, 118, 240, 0.09)',
      border: '1px solid #5F82FF',
    },
  },
}))
