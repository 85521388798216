import { Image, Text, Title, Center} from '@mantine/core';
import Emilson from './emilson.jpg';
import _Emilson from './emilson.webp';
import { useNavigate } from 'react-router-dom';
import ReactGA from "react-ga";
export default function NotFound() {
  const navigate = useNavigate();
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div
      style={{
        margin: '15px',
        color: '#f5f5f5',
      }}
    >
      <Title
        align="center"
        sx={(theme) => ({
          fontSize: '8em',
          color: '#f5f5f5',
        })}
      >
        404
      </Title>
      <p style={{ textAlign: 'center', fontSize: '14pt' }}>
        Estou a dizer que não é possível isso Emilson
      </p>

      <Center>
        <picture>
          <source srcSet={_Emilson} type="image/webp" />
          <img width="344" height="401" src={Emilson} />
        </picture>
      </Center>

      <p style={{ textAlign: 'center', fontSize: '14pt' }}>
        Não encontramos a página que procuravas
      </p>
      <Text
        onClick={() => {
          navigate('/');
        }}
        align="center"
        size="lg"
        color="#A4B7FF"
      >
        Voltar para início →
      </Text>
    </div>
  );
}
