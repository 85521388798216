import {Container} from "@mantine/core";

export default function () {
  return (
    <Container sx={{color: "white"}}>
      <h1>Política de Privacidade</h1>
      <p>Última actualização: 08 de Outubro de 2023</p>
      <p>
        Esta Política de Privacidade descreve como as informações pessoais são
        colectadas, usadas e compartilhadas quando você utiliza o nosso
        aplicativo de exames ("Aplicativo"). Ao usar o Aplicativo, você concorda
        com a colecta e o uso de suas informações pessoais de acordo com esta
        Política de Privacidade.
      </p>

      <h2>1. Informações Colectadas</h2>
      <ul>
        <li>
          Informações de identificação, como nome, endereço de e-mail e
          informações de contacto.
        </li>
        <li>Informações sobre seus exames, respostas e desempenho.</li>
        <li>
          Informações técnicas, como endereço IP, tipo de dispositivo, sistema
          operacional e informações sobre a rede.
        </li>
        <li>
          Informações sobre seu uso do Aplicativo, incluindo páginas visitadas e
          interacções.
        </li>
      </ul>

      <h2>2. Uso das Informações</h2>
      <p>
        Utilizamos as informações pessoais coletadas para os seguintes fins:
      </p>
      <ul>
        <li>Fornecer acesso aos exames e conteúdos do Aplicativo.</li>
        <li>Personalizar sua experiência no Aplicativo.</li>
        <li>Analisar o desempenho e a eficácia do Aplicativo.</li>
        <li>Enviar actualizações e notificações relacionadas ao Aplicativo.</li>
        <li>Cumprir com obrigações legais e regulatórias.</li>
      </ul>

      <h2>3. Compartilhamento das Informações</h2>
      <p>
        Não compartilhamos suas informações pessoais com terceiros, exceto nas
        seguintes circunstâncias:
      </p>
      <ul>
        <li>
          Compartilhamento necessário para fornecer os serviços do Aplicativo.
        </li>
        <li>Com seu consentimento expresso.</li>
        <li>Cumprimento de obrigações legais.</li>
      </ul>

      <h2>4. Segurança das Informações</h2>
      <p>
        Tomamos medidas razoáveis para proteger suas informações pessoais contra
        acesso não autorizado, alteração, divulgação ou destruição. No entanto,
        não podemos garantir a segurança absoluta das informações transmitidas
        ou armazenadas electronicamente.
      </p>

      <h2>5. Seus Direitos</h2>
      <p>
        Você tem o direito de acessar, corrigir ou excluir suas informações
        pessoais. Se desejar exercer esses direitos ou tiver dúvidas sobre nossa
        Política de Privacidade, entre em contacto connosco através das
        informações de contacto fornecidas no Aplicativo.
      </p>

      <h2>6. Alterações na Política de Privacidade</h2>
      <p>
        Podemos actualizar esta Política de Privacidade de tempos em tempos. A
        data da última actualização será indicada no início do documento.
        Recomendamos que você revise periodicamente esta Política para estar
        ciente de qualquer alteração.
      </p>

      <p>
        Ao utilizar o Aplicativo, você concorda com os termos desta Política de
        Privacidade.
      </p>

      <p className="contact-info">Wei Technologies +258849902307</p>
    </Container>
  );
}
