import {
  Title,
  Text,
  Button,
  Stack,
  Box,
  Grid,
  RingProgress,
  Center,
  Skeleton,
} from '@mantine/core';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import Confetti from 'react-confetti';
import Score from './components/Score';

export default function Results() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { state } = location;
  const {
    time,
    examId,
    answers,
    pathname
  } = state;
  ReactGA.pageview(window.location.pathname + window.location.search);
  const width = window.innerWidth;
  const height = window.innerHeight;
  const [isCofettiVisible, setIsCofettiVisible] = useState(true);
  const [score, setScore] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [label, setLabel] = useState('');
  const [questionsWithCorrection, setQuestionsWithCorrection] = useState([]);
  const [examSlug, setExamSlug] = useState('');
  useEffect(() => {
    fetch(
      `https://wei-backend.herokuapp.com/submit?${
        pathname !== 'topico' ? 'examId' : 'topicId'
      }=${examId}`,
      {
        method: 'POST',
        body: JSON.stringify({
          time: {
            minutes: time.minutes,
            seconds: time.seconds,
          },
          answers: answers,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setScore(data.score);
        setLabel(data.label)
        setExamSlug(data.examSlug)
        setQuestionsWithCorrection(data.questionsWithCorrection);
        setCorrectAnswers(data.correctAnswers);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  if (!isLoading) {
    setTimeout(() => {
      setIsCofettiVisible(false);
    }, 4000);
  }
  const lowMarkMessages = [
    'Se mesmo esses que escrevem livros dizem que Moçambique está na África ocidental, quem és tu para acertar logo de primeira 😭',
    'Sabias que saem balões quando tiras acima de 10? indje uta swi vhona leswo? 😭',
  ];

  const scoreGroup =
    score <= 5 ? 'LOW' : score >= 5 && score <= 10 ? 'MEDIUM' : 'HIGH';

  return (
    <Center m="xs" mt={'lg'}>
      <Stack>
        {!isLoading && isCofettiVisible && scoreGroup === 'HIGH' && (
          <Confetti width={width} height={height} />
        )}
        {isLoading && <Skeleton height={50} />}
        {!isLoading && (
          <Title align="center" mt={18} order={3} color="#f5f5f5">
            Exame concluído 🎉
          </Title>
        )}
        {isLoading && <Skeleton height={50} />}
        {!isLoading && (
          <Text align="center" size="lg" mt={18} color="#c9c9c9">
            {!isLoading &&
              scoreGroup === 'LOW' &&
              lowMarkMessages[
                Math.floor(Math.random() * lowMarkMessages.length)
              ]}
            {!isLoading &&
              scoreGroup === 'MEDIUM' &&
              'A prática nos aproxima dos bons resultados, ainda podes melhorar, não desistas ! 🥹'}
            {!isLoading &&
              scoreGroup === 'HIGH' &&
              'BIM: 112312928311231 Esse é o número de conta para fazer inscrição, porque assim? já estás admitido! 🙌'}
          </Text>
        )}
        {isLoading && <Skeleton height={80} />}
          <RingProgress
            size={350}
            align="center"
            sx={{ margin: '0 auto', display: isLoading ? "hidden": undefined}}
            thickness={17}
            sections={[
              {
                value: (score * 100) / 20,
                color:
                  scoreGroup === 'LOW'
                    ? '#CE2D4F'
                    : scoreGroup === 'MEDIUM'
                      ? '#F4AC45'
                      : '#49A078',
              },
            ]}
            label={<Score score={score} />}
          />
        <>
          {!isLoading && (
            <Grid
              sx={{
                borderRadius: '10px',
                border: '.5px solid white',
                margin: 0,
              }}
            >
              <Grid.Col span={6}>
                <Box>
                  <Text
                    sx={{ textAlign: 'center', color: '#ffffff85' }}
                    size="md"
                    m="xs"
                  >
                    Tempo
                    <Text sx={{ textAlign: 'center' }} size="md" color="white">
                      {time.minutes}:{time.seconds}
                    </Text>
                  </Text>
                </Box>
              </Grid.Col>
              <Grid.Col span={6}>
                <Box
                  sx={{
                    borderLeft: '.5px solid white',
                  }}
                >
                  <Text
                    sx={{ textAlign: 'center', color: '#ffffff85' }}
                    size="md"
                    m="xs"
                  >
                    Respostas certas
                    <Text sx={{ textAlign: 'center' }} size="md" color="white">
                      {correctAnswers}
                    </Text>
                  </Text>
                </Box>
              </Grid.Col>
            </Grid>
          )}
        </>
        {isLoading && <Skeleton height={50} />}
        {!isLoading && (
          <Button
            sx={() => ({
              background:
                'linear-gradient(90deg, rgba(111,137,244,1) 62%, rgba(245,154,244,1) 90%, rgba(252,189,205,1) 98%)',
              color: '#f5f5f5',
              display: 'inline-block',
              height: '44px',
            })}
            onClick={() =>
              navigate(`/resolucao/${examSlug}`, {
                state: { questionsWithCorrection, label },
              })
            }
          >
            Ver Correcção
          </Button>
        )}
        {isLoading && <Skeleton height={50} />}
        {!isLoading && (
          <Button
            variant="subtle"
            sx={{ color: '#A4B7FF', fontSize: '12pt' }}
            onClick={() => navigate('/')}
          >
            Início
          </Button>
        )}
      </Stack>
    </Center>
  );
}
