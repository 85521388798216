import { Text, Box, List, Image } from '@mantine/core';
import CheckIconPath from './check.svg';
import PriceCardStyles from './PriceCard.styles';
export default function PriceCard({ price: pricing }) {
  let { title, price, features, isSelected } = pricing;
  const classes = PriceCardStyles();
  const CheckIcon = <Image src={CheckIconPath} />;
  return (
    <Box classNames={classes.box}>
      <Text size="md" color="#252427t" weight="600">
        {title}
      </Text>
      <Text
        size="xl"
        weight="500"
        color="#252427t"
        style={{ marginBottom: '10px' }}
      >
        {price} MT/mês
      </Text>
      <List
        spacing="sm"
        size="sm"
        center
        icon={CheckIcon}
        style={{ marginLeft: '0' }}
        color="#252427t"
      >
        {features.map((feature) => (
          <List.Item>{feature}</List.Item>
        ))}
      </List>
    </Box>
  );
}
